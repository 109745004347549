import React from "react";
import { Text, Paper, Stack, List, Flex } from "@mantine/core";

const RichContentBlock = ({ data }) => {
	return (
		<Stack spacing={16}>
			{data?.map((section, index) => {
				if (section.type === "paragraph") {
					return section.children.map((item, i) => (
						<Text align="left" size={"sm"} weight={400} key={i}>
							{item.text}
						</Text>
					));
				} else if (section.type === "list") {
					return (
						<Stack spacing={4} key={index}>
							{section.children.map((item, i) => (
								<Flex gap={4} align={"center"} key={i}>
									<Text color="green" className="ri-checkbox-circle-line"></Text>
									<Text align="left" size={"sm"} weight={500}>
										{item.children[0].text}
									</Text>
								</Flex>
							))}
						</Stack>
					);
				}
				return null;
			})}
		</Stack>
	);
};

export default RichContentBlock;
