import { Button, Center, Group, Image, Stack, Text } from "@mantine/core";

export default function EmptyList({
  title,
  description,
  buttonLink,
  buttonFunction,
  buttonTitle,
  imageEmtyState,
}: {
  title?: any;
  description?: any;
  buttonLink?: any;
  buttonFunction?: any;
  buttonTitle?: any;
  imageEmtyState?: any;
}) {
  return (
    <Stack>
      <Center>
        {imageEmtyState && (
          <Image
            radius="lg"
            src={imageEmtyState}
            alt="empty wedding"
            style={{ maxWidth: "200px" }}
          />
        )}
      </Center>
      <Stack spacing={0}>
        <Text align="center" size="md" weight={700}>
          {title}
        </Text>
        <Text align="center" size="sm">
          {description}
        </Text>
        {buttonTitle && (
          <Group position="center" mt="md">
            {buttonLink && (
              <Button
                radius="md"
                size="md"
                type="submit"
                variant="filled"
                color="pink"
                component="a"
                href={buttonLink}
              >
                {buttonTitle}
              </Button>
            )}
            {buttonFunction && (
              <Button
                radius="md"
                size="md"
                type="submit"
                variant="filled"
                color="pink"
                component="a"
                onClick={buttonFunction}
              >
                {buttonTitle}
              </Button>
            )}
          </Group>
        )}
      </Stack>
    </Stack>
  );
}
