import {
	ActionIcon,
	Alert,
	Badge,
	Button,
	Card,
	Divider,
	Drawer,
	Flex,
	Group,
	Stack,
	Text,
	TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import moment from "moment-timezone";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import useSWR, { mutate } from "swr";
import RichContentBlock from "../../organisms/content/RichBlock.content";
import { fetchAllPlanData } from "../../../utils/api-request/fetch-all-plan";
import { v4 as uuidv4 } from "uuid";
import Link from "next/link";
import useIsSubmittingStore from "../../../states/stores/is-submitting";
import useAccountStore from "../../../states/stores/account/account.store";
import { signOut, useSession } from "next-auth/react";

declare global {
	interface Window {
		snap: any; // Replace 'any' with the actual type of snap if known
	}
}

interface FormValues {
	brandId: any;
	referrerCode: string;
	subscriptionDue: any;
	subscriptionPlan: {
		id: number;
	};
}

export default function PlanInfo({ data, title }: { data?: any; title?: any }) {
	const router = useRouter();
	const currentRoute = router.pathname;
	// const { data: session } = useSession();
	const userData = useAccountStore((state) => state.account);
	const userDataValidating = useAccountStore((state) => state.accountValidating);
	const { setIsSubmitting, isSubmitting } = useIsSubmittingStore();
	const paymentFee = 4500;
	const [openDrawerSubscription, setOpenDrawerSubscription] = useState(null);
	const [payLink, setPayLink] = useState(null);
	const [selectedPlan, setSelectedPlan] = useState(null);
	const [originalSubscriptionDue, setOriginalSubscriptionDue] = useState(null);
	const [isSubscriptionExpired, setIsSubscriptionExpired] = useState(false);
	const [isSubscriptionWillExpired, setIsSubscriptionWillExpired] = useState(false);
	const [submitting, setSubmitting] = useState(true);
	const [referralDiscountData, setReferralDiscountData] = useState(null);

	// console.log(data);

	const [orderData, setOrderData] = useState(null);
	const form = useForm<FormValues>();

	// console.log("data brand: ", data)
	// console.log("session: ", session)

	// if (userData === 401) {
	//    signOut();
	//  }

	useEffect(() => {
		if (new URLSearchParams(window.location.search).get("plan") === "true") {
			setOpenDrawerSubscription(true);
		}
	}, []);

	const currencyFormatter = new Intl.NumberFormat("id-ID", {
		style: "currency",
		currency: "IDR",
		minimumFractionDigits: 0, // Set to 0 to remove cents
		maximumFractionDigits: 0, // Set to 0 to remove cents
	});

	const { data: allPlan, error: allPlanError } = useSWR(
		openDrawerSubscription ? "/api/v1/plan/all-plan?type=moment" : null,
		(url) =>
			fetch(url)
				.then((res) => res.json())
				.then((data) => data.data),
		{
			dedupingInterval: 60000,
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
		},
	);

	const {
		data: referralData,
		error: referralDataError,
		isValidating: referralDataValidating,
	} = useSWR(
		openDrawerSubscription ? "/api/v1/account/referral/code" : null,
		(url) =>
			fetch(url)
				.then((res) => res.json())
				.then((data) => data?.data[0]),
		{
			dedupingInterval: 60000, // 1 minute deduping interval
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
		},
	);

	const submitReferralCheck = async (values: FormValues) => {
		const valueData = {
			code: values.referrerCode,
		};

		const endpoint = `/api/v1/referral/code-check?code=${valueData?.code?.toUpperCase()}`;

		const options = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
			// body: JSON.stringify(valueData),
		};

		showNotification({
			id: "load-data",
			loading: true,
			color: "teal",
			title: "Kode referral lagi di cek",
			message: "Mohon tunggu ya",
			// icon: <IconCheck size={16} />,
			autoClose: false,
			withCloseButton: false,
		});

		const response = await fetch(endpoint, options);
		const data = await response.json();

		if (response.status === 200 && !data?.message) {
			updateNotification({
				id: "load-data",
				loading: false,
				color: "teal",
				title: "Kode referral berhasil dicek",
				message: "Pesan akan hilang dalam beberapa saat",
				icon: <i className="ri-heart-2-line"></i>,
				autoClose: 1000,
				withCloseButton: false,
			});

			setReferralDiscountData(data?.data[0]);

			// mutate("/api/v1/account/me", data, true);

			form.setValues({
				...data,
				...form.values,
			});
		}

		if (response.status === 400 || data?.message || data?.data[0]?.code === referralData?.code) {
			updateNotification({
				id: "load-data",
				color: "red",
				title: "Ups.. Error nih!!",
				message: data?.error?.message || data?.message,
				icon: <i className="ri-heart-2-line"></i>,
				// autoClose: 1500,
			});
			setReferralDiscountData(null);
		}
	};

	useEffect(() => {
		if (data && data?.activePeriod) {
			const subscriptionDueDate = new Date(data?.activePeriod);

			// Calculate the difference in days between the subscriptionDueDate and today
			const daysUntilDue = Math.ceil((subscriptionDueDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));

			if (daysUntilDue >= 0 && daysUntilDue <= 3) {
				// setIsSubscriptionWillExpired(false);
				setIsSubscriptionWillExpired(true);
			} else if (daysUntilDue <= -1) {
				// setIsSubscriptionExpired(false);
				setIsSubscriptionExpired(true);
			} else {
				setIsSubscriptionExpired(false);
				setIsSubscriptionWillExpired(false);
			}

			setOriginalSubscriptionDue(subscriptionDueDate);
		} else {
			// If data.subscriptionDue is null, set it to the current date
			const initialDueDate = new Date();

			setOriginalSubscriptionDue(initialDueDate);
			setIsSubscriptionExpired(false);
			setIsSubscriptionWillExpired(false);
		}
	}, [data]);

	useEffect(() => {
		if (data) {
			// Initialize the form with data when it's available
			form.setValues({
				brandId: data?.id || "",
				subscriptionDue: data?.subscriptionDue || "",
				referrerCode: form.values.referrerCode || null,
				subscriptionPlan: {
					id: data?.subscriptionPlan?.uuid || "",
				},
			});
		}
	}, [data]);

	const handleSelectPlan = (plan) => {
		// Calculate the new SubscriptionDue date based on the selected plan's interval
		if (plan.durationDay) {
			const newDueDate = new Date(originalSubscriptionDue.getTime());
			newDueDate.setDate(newDueDate.getDate() + plan.durationDay);
		}

		// Set the selected plan
		setSelectedPlan(plan);
	};

	const displayTotalBayar = () => {
		const totalBayar =
			selectedPlan?.salePrice !== null && selectedPlan?.salePrice >= 0 ? selectedPlan?.salePrice : selectedPlan?.price;

		let diskonReferral = 0;
		if (referralDiscountData) {
			// Jika tipe diskon adalah persentase
			if (referralDiscountData?.typeForDiscount === "percentage") {
				// Ganti selectedPlan?.referralDiscountInPercent dengan referralDiscountData?.discountNominal
				diskonReferral = (referralDiscountData?.discountNominal / 100) * totalBayar;
			}
			// Jika tipe diskon adalah flat
			else if (referralDiscountData?.typeForDiscount === "flat") {
				// Langsung gunakan referralDiscountData?.discountNominal
				diskonReferral = referralDiscountData?.discountNominal;
			}
		}

		// Menghitung total bayar setelah diskon
		const totalBayarSetelahDiskon =
			(form.values.referrerCode ? totalBayar - diskonReferral : totalBayar) <= 0
				? 0
				: (form.values.referrerCode ? totalBayar - diskonReferral : totalBayar) + paymentFee;

		return currencyFormatter.format(totalBayarSetelahDiskon);
	};

	const handlePaymentSuccess = async (dataOrder) => {
		const endpoint = `/api/v1/event/plan-activation`;
		const options = {
			method: "PUT",
			headers: {
				// Authorization: "Bearer " + session.jwt,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				durationDay: dataOrder?.dataDetailOrder?.data?.billingType[0]?.durationDay,
				currentSubscriptionDue: data?.activePeriod,
				subscriptionPlanId: dataOrder?.dataDetailOrder?.data?.billingType[0]?.subscriptionPlanId,
				subscriptionPlanUuId: dataOrder?.dataDetailOrder?.data?.billingType[0]?.subscriptionPlanUuid,
				orderId: dataOrder?.dataDetailOrder?.data?.id,
				event: data?.id,
				features: dataOrder?.dataDetailOrder?.data?.features,
			}),
		};

		showNotification({
			id: "load-data",
			loading: true,
			color: "teal",
			title: "Aktifasi sedang di proses",
			message: "Jangan ditutup yaa 🙏",
			// icon: <IconCheck size={16} />,
			autoClose: false,
			withCloseButton: false,
		});

		const response = await fetch(endpoint, options);
		// try {
		const newData = await response.json();

		if (response.status === 200) return window.location.reload();

		if (response.status === 400) {
			updateNotification({
				id: "load-data",
				color: "red",
				title: "Ups.. Error nih!!",
				message: newData.error.message,
				icon: <i className="ri-heart-2-line"></i>,
				// autoClose: 1500,
			});
		}
	};

	const submitSelectedPlan = async () => {
		setSubmitting(true);

		const totalBayar =
			selectedPlan?.salePrice !== null && selectedPlan?.salePrice >= 0 ? selectedPlan?.salePrice : selectedPlan?.price;

		let diskonReferral = 0;
		if (referralDiscountData) {
			// Jika tipe diskon adalah persentase
			if (referralDiscountData?.typeForDiscount === "percentage") {
				// Ganti selectedPlan?.referralDiscountInPercent dengan referralDiscountData?.discountNominal
				diskonReferral = (referralDiscountData?.discountNominal / 100) * totalBayar;
			}
			// Jika tipe diskon adalah flat
			else if (referralDiscountData?.typeForDiscount === "flat") {
				// Langsung gunakan referralDiscountData?.discountNominal
				diskonReferral = referralDiscountData?.discountNominal;
			}
		}

		// Menghitung total bayar setelah diskon
		const totalBayarSetelahDiskon = form.values.referrerCode ? totalBayar - diskonReferral : totalBayar;

		const valueData = {
			event: data.id,
			features: selectedPlan?.features,
			currentSubscriptionDue: data.activePeriod,
			email: data.email,
			name: data.name,
			whatsApp: !userDataValidating && userData?.whatsAppNumber,
			subscriptionPlanId: selectedPlan?.id,
			subscriptionPlanUuid: selectedPlan?.uuid,
			subscriptionPlanName: selectedPlan?.name,
			subscriptionPlanPrice:
				selectedPlan?.salePrice !== null && selectedPlan?.salePrice >= 0
					? selectedPlan?.salePrice
					: selectedPlan?.price,
			// totalCharge: totalBayarSetelahDiskon === 0 ? 0 : totalBayarSetelahDiskon + paymentFee,
			totalCharge: totalBayarSetelahDiskon === 0 ? 0 : totalBayarSetelahDiskon + paymentFee,
			durationDay: selectedPlan?.durationDay,
			// referralDiscount: selectedPlan?.referralDiscountInPercent,
			referrerCodeId: referralDiscountData?.id ?? null,
			referrerTypeOfCommission: referralDiscountData?.typeForCommission ?? null,
			referrerCommissionNominal: referralDiscountData?.commissionNominal ?? null,
			fromReferralTypeOfDiscount: referralDiscountData?.typeForDiscount ?? null,
			fromReferralDiscountNominal: referralDiscountData?.discountNominal ?? null,
			fromReferralDiscountAmount: diskonReferral ?? null,
			// midtrans
			order_id: uuidv4(),
			items: [
				{
					id: "0001",
					price: totalBayar,
					quantity: 1,
					name: selectedPlan?.name,
				},
				{
					id: "0002",
					price: paymentFee,
					quantity: 1,
					name: "Biaya Pembayaran",
				},
				{
					id: "0003",
					price: referralDiscountData ? -diskonReferral : 0,
					quantity: 1,
					name: "Diskon Referral",
				},
				{
					id: "0004",
					price: 0,
					quantity: 1,
					name: "Diskon Bahagia",
				},
				// {
				//   id: "0003",
				//   price: 0,
				//   quantity: 1,
				//   name: "Pajak",
				// },
			],
		};

		const endpoint = `/api/v1/event/checkout`;
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify(valueData),
		};

		showNotification({
			id: "load-data",
			loading: true,
			color: "teal",
			title: "Pembelian langganan lagi dikirim nih",
			message: "Mohon tunggu yaaa 😊",
			// icon:<IconCheck size={16} />,
			autoClose: false,
			withCloseButton: false,
		});
		const response = await fetch(endpoint, options);
		const newData = await response.json();

		if (response.status === 200) {
			setOrderData(valueData);

			updateNotification({
				id: "load-data",
				loading: false,
				color: "teal",
				title: "Pesanan langganan berhasil dibuat",
				message: "Silahkan dibayar yaaa 😊",
				icon: <i className="ri-heart-2-line"></i>,
				autoClose: 1000,
				withCloseButton: false,
			});

			setOpenDrawerSubscription(null);
			setSubmitting(false);
			setPayLink(newData);

			// (window as any).snap.pay(newData.snapToken);

			if (totalBayarSetelahDiskon === 0) {
				location.reload();
			} else {
				window.snap.pay(newData?.snapToken, {
					onSuccess: function (result) {
						handlePaymentSuccess(newData);
					},
					onPending: function (result) {
						location.reload();
					},
					onError: function (result) {
						location.reload();
					},
					onClose: function () {
						location.reload();
					},
				});
			}
		}

		if (response.status === 400) {
			updateNotification({
				id: "load-data",
				color: "red",
				title: "Ups.. Error nih!!",
				message: newData.error.message,
				icon: <i className="ri-heart-2-line"></i>,
				// autoClose: 1500,
			});
			setSubmitting(false);
		}
	};

	const mobileScreen = useMediaQuery("(max-width: 540px)");
	// console.log(payLink);

	return (
		<>
			<Drawer
				opened={openDrawerSubscription}
				onClose={() => {
					setOpenDrawerSubscription(null);
					setPayLink(null);
				}}
				overlayProps={{ opacity: 0.2, blur: 3 }}
				padding="md"
				title={title}
				// title={payLink === null ? title : "Rincian Pembayaran"}
				withCloseButton={false}
				zIndex={20}
				closeOnEscape={false}
				closeOnClickOutside={false}
				position={mobileScreen ? "bottom" : "right"}
				size={mobileScreen ? "auto" : "sm"}
			>
				<Stack>
					<Stack spacing={16}>
						{!allPlan
							? "👋 Loading..."
							: // : payLink === null &&
								allPlan?.map((plan) => (
									<button
										onClick={() => handleSelectPlan(plan)}
										disabled={selectedPlan && selectedPlan?.uuid === plan.uuid}
										key={plan.uuid}
										style={{
											background: "none",
											border: "none",
											padding: 0,
											margin: 0,
											cursor: "pointer",
											textDecoration: "none",
											color: "blue", // You can adjust the color as needed
										}}
									>
										{plan && plan.isFeatured ? (
											<Card
												radius="lg"
												// withBorder
												bg={"#FEF1F6"}
												sx={
													selectedPlan && selectedPlan?.uuid === plan.uuid
														? { border: "1px solid #E64980" }
														: { border: "none" }
												}
											>
												<Stack align="flex-start">
													<Stack align="flex-start" spacing={0}>
														<Text size={"lg"} weight={500}>
															{plan.name}
														</Text>
														<Text align="left" color={"#209c37"} size={"sm"} weight={400}>
															{plan.description}
														</Text>
														<Text component="p" p={0} m={0} color="pink" size={"sm"} weight={500}>
															{plan.salePrice !== null && plan.salePrice >= 0 && (
																<Text
																	component="span"
																	sx={{ textDecoration: "line-through" }}
																	color="gray.6"
																	weight={400}
																	mr={6}
																>
																	{currencyFormatter.format(plan.price)}
																</Text>
															)}{" "}
															{plan.salePrice !== null && plan.salePrice >= 0
																? plan.salePrice === 0
																	? "Gratis"
																	: currencyFormatter.format(plan.salePrice)
																: currencyFormatter.format(plan.price)}
														</Text>
													</Stack>
													{plan.content && <RichContentBlock data={plan.content} />}
													{/* {plan.features !== null && (
														<Flex wrap={"wrap"}>
															{plan.features.map((feature, i) => (
																<Badge radius="md" size="sm" color="gray.7" variant="light">
																	{feature === "digital_invitation" && "Undangan Digital"}
																	{feature === "budget_planner" && "Budget Planner"}
																	{feature === "checklist" && "Checklist"}
																	{feature === "moodboard" && "Moodboard"}
																</Badge>
															))}
														</Flex>
													)} */}
												</Stack>
											</Card>
										) : (
											<Card
												radius="lg"
												// withBorder
												bg={"#f0f0f0"}
												sx={
													selectedPlan && selectedPlan?.uuid === plan.uuid
														? { border: "1px solid #E64980" }
														: { border: "none" }
												}
											>
												<Stack align="flex-start" spacing={16}>
													<Stack align="flex-start" spacing={0}>
														<Text size={"lg"} weight={500}>
															{plan.name}
														</Text>
														<Text align="left" color={"#209c37"} size={"sm"} weight={400}>
															{plan.description}
														</Text>
														<Text component="p" p={0} m={0} color="pink" size={"sm"} weight={500}>
															{plan.salePrice !== null && plan.salePrice >= 0 && (
																<Text
																	component="span"
																	sx={{ textDecoration: "line-through" }}
																	color="gray.6"
																	weight={400}
																	mr={4}
																>
																	{currencyFormatter.format(plan.price)}
																</Text>
															)}{" "}
															{plan.salePrice !== null && plan.salePrice >= 0
																? plan.salePrice === 0
																	? "Gratis"
																	: currencyFormatter.format(plan.salePrice)
																: currencyFormatter.format(plan.price)}
														</Text>
													</Stack>
													{plan.content && <RichContentBlock data={plan.content} />}
													{/* {plan.features !== null && (
														<Flex wrap={"wrap"}>
															{plan.features.map((feature, i) => (
																<Badge radius="md" size="sm" color="gray.7" variant="light">
																	{feature === "digital_invitation" && "Undangan Digital"}
																	{feature === "budget_planner" && "Budget Planner"}
																	{feature === "checklist" && "Checklist"}
																	{feature === "moodboard" && "Moodboard"}
																</Badge>
															))}
														</Flex>
													)} */}
												</Stack>
											</Card>
										)}
									</button>
								))}

						{selectedPlan && (
							<>
								{/* {payLink === null && data?.canUseReferral === null && ( */}
								<Stack spacing={16}>
									<Alert radius={"md"} title="Waah, kabar baik niih 😁" color="green">
										<Stack spacing={8}>
											<Text size={13}>Masukin KODE REFERRAL dibawah maka kamu bisa dapet diskon loooh!</Text>
										</Stack>
									</Alert>
									<form onSubmit={form.onSubmit(submitReferralCheck)}>
										<TextInput
											description={`Kode Referral (Jika ada)`}
											rightSection={
												<Button color="#FFFFFF" variant="subtle" compact uppercase type="submit">
													<Text weight={500} sx={{ cursor: "pointer" }} color="pink" size={14} pr={8}>
														CEK
													</Text>
												</Button>
											}
											size="md"
											radius="md"
											type="text"
											value={form.values.referrerCode?.toUpperCase() || ""}
											onChange={(e) => form.setValues({ referrerCode: e.target.value })}
										/>
									</form>
								</Stack>

								<Divider my="4px" color="#e9ecef" />
								<Stack spacing={4}>
									<Text component="p" p={0} m={0} size={"sm"}>
										Harga Paket:{" "}
										{selectedPlan?.salePrice !== null && selectedPlan?.salePrice >= 0
											? currencyFormatter.format(selectedPlan?.salePrice)
											: currencyFormatter.format(selectedPlan?.price)}
									</Text>
									{referralDiscountData && (
										<Text component="p" p={0} m={0} size={"sm"} color="green">
											Diskon Referral:{" "}
											<Text component="span" color="green" weight={500}>
												-{" "}
												{referralDiscountData?.typeForDiscount === "percentage" &&
													currencyFormatter.format(
														(referralDiscountData?.discountNominal / 100) *
															(selectedPlan?.salePrice !== null && selectedPlan?.salePrice >= 0
																? selectedPlan?.salePrice === 0
																	? selectedPlan?.salePrice
																	: selectedPlan?.salePrice
																: selectedPlan?.price),
													)}
												{referralDiscountData?.typeForDiscount === "flat" &&
													currencyFormatter.format(referralDiscountData?.discountNominal)}
											</Text>
											<Text component="span" weight={500} color="green">
												{referralDiscountData?.typeForDiscount === "percentage" &&
													` (${referralDiscountData?.discountNominal}%)`}
											</Text>
										</Text>
									)}
									<Text component="p" p={0} m={0} size={"sm"}>
										Biaya Pembayaran: {selectedPlan?.salePrice === 0 ? "0" : paymentFee}
									</Text>
									<Text component="p" p={0} m={0} size={"sm"} color="orange" weight={600}>
										Total Bayar: {displayTotalBayar()}
									</Text>
								</Stack>
								<Divider my="4px" color="#e9ecef" />
							</>
						)}
					</Stack>

					<Group mt={16} align="center">
						<Button
							onClick={() => setOpenDrawerSubscription(null)}
							sx={{
								width: "calc(100% / 2 - 16px)",
								backgroundColor: "#f0f0f0",
							}}
							color="dark"
							variant="subtle"
							radius="md"
						>
							Batal
						</Button>
						<Button
							sx={{
								width: "calc(100% / 2 - 16px)",
								backgroundColor: "#FEF1F6",
							}}
							color={"pink"} // Change the color as needed
							variant="subtle"
							radius="md"
							type="submit"
							onClick={() => {
								submitSelectedPlan();
								// checkout();
							}}
							// disabled={!submitting}
						>
							Beli 🔥
						</Button>
					</Group>
				</Stack>
			</Drawer>

			<Card radius="lg" sx={{ backgroundColor: "#FEF1F6" }}>
				<Stack spacing={4}>
					<Group position="apart" spacing="xs">
						{data?.activePeriod ? (
							<Stack spacing={2}>
								<Group spacing={4}>
									<Text component="p" size={"xs"} weight={400} p={0} m={0} color="#676a6e">
										Akses edit sampai
									</Text>
								</Group>
								<Text component="p" size={"sm"} weight={400} p={0} m={0}>
									{/* {moment.utc(data?.activePeriod).tz("Asia/Jakarta").format("DD MMMM YYYY HH:mm")} */}
									{moment.utc(data?.activePeriod).tz("Asia/Jakarta").format("DD MMMM YYYY")}
								</Text>
								{isSubscriptionExpired && (
									<Text size={"xs"} weight={500} color="orange">
										Masa akses habis 🥺
									</Text>
								)}
								{isSubscriptionWillExpired && (
									<Text size={"xs"} weight={500} color="orange">
										Masa akses segera habis 😱
									</Text>
								)}
							</Stack>
						) : (
							<Text size={"sm"} weight={500} color="red">
								Belum ada paket aktif
							</Text>
						)}

						<Stack spacing={0}>
							<Text
								component="a"
								size="sm"
								weight={600}
								p={0}
								m={0}
								color="pink"
								sx={{ cursor: "pointer" }}
								onClick={() => {
									setOpenDrawerSubscription(data);
								}}
							>
								{/* {data?.billingHistories?.length > 0 ? "+ Upgrade" : "Pilih Paket"} */}+ Upgrade
							</Text>
						</Stack>
					</Group>
					{/*
					<Divider my="4px" color="#dddddd" />

					<Stack align="left" spacing={4}>
						<Text component="p" size={"sm"} weight={400} p={0} m={0} color="#676a6e">
							Paket
						</Text>
						{!data?.currentPlan?.name ? (
							<Text component="p" size={"sm"} weight={400} color="green" p={0} m={0}>
								Paket Gratis
							</Text>
						) : (
							<Text component="p" size={"sm"} weight={400} p={0} m={0}>
								{data?.currentPlan?.name}
							</Text>
						)}
						<Flex mt={6} gap={4}>
							{data?.currentPlan?.name &&
								data?.currentPlan?.features.map((feature, i) => (
									<Badge key={i} radius="md" size="xs" color={"pink"} variant="outline">
										<Text size={10} weight={500} color="gray.7">
											{feature === "digital_invitation" && "Undangan Web"}
											{feature === "checklist" && "Wedding Checklist"}
											{feature === "budget_planner" && "Budget Planner"}
											{feature === "moodboard" && "Moodboard Inspirasi"}
										</Text>
									</Badge>
								))}
						</Flex>
					</Stack> */}

					{/* {currentRoute !== "/billing" && (
							<Link style={{ display: "flex", justifyContent: "center" }} href="/billing">
								<Text component="span" size={"sm"} underline weight={500} color="dark" p={0} m={0}>
									Riwayat
								</Text>
							</Link>
						)} */}
				</Stack>
			</Card>
		</>
	);
}
